import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { mapIndexed } from '../lib/Helpers'

library.add(fab)

export default () => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        title
        main_contact_email
        social_links {
          title
          url
          icon
        }
      }
    }
  `)

  const {
    sanitySiteSettings: { social_links }
  } = data

  return (
    <footer>
      <div className="container">
        <h4 className="text--center title">
          Connect with {data.sanitySiteSettings.title}
        </h4>
        <ul className="text--center footer-links">
          <li>
            <AniLink
              className="link link--white"
              cover
              to="/contact-support"
              duration={1.25}
              direction="right"
              bg={`
                center / 50% /* position / size */
                no-repeat        /* repeat */
                fixed            /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                #00021a          /* color */
              `}
            >
              <FontAwesomeIcon icon={faEnvelope} mask={['circle']} size="2x" />
            </AniLink>
          </li>
          {mapIndexed((link, index) => {
            return (
              <li key={index}>
                <a
                  href={link.url}
                  className="link link--white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={['fab', `${link.icon}`]}
                    className="fb_icon"
                    size="2x"
                  />
                </a>
              </li>
            )
          })(social_links)}
        </ul>
        <p className="text--center">
          {new Date().getFullYear()} &copy; All Rights Reserved.
        </p>
      </div>
    </footer>
  )
}
