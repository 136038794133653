import * as R from 'ramda'
import React from 'react'

import { notNilOrEmpty } from '../lib/Helpers'

export default props => {
  return (
    <div
      className={`hero hero--${props.className}`}
      style={{
        background: props.img
          ? `#000 url(${props.img}) center / cover no-repeat`
          : '#000'
      }}
    >
      <div className="hero--overlay" />
      <div className="hero__content container">
        {notNilOrEmpty(props.overlayImg) && (
          <img
            alt="icon in the hero section"
            className={
              R.equals(props.className, 'home-page')
                ? 'hero__content__img'
                : `hero--${props.className}__overlay-image`
            }
            src={props.overlayImg}
          />
        )}
        {props.title && <h3 className="hero__content__title">{props.title}</h3>}
        {props.subTitle && (
          <p className="hero__content__sub-title">{props.subTitle}</p>
        )}
      </div>
    </div>
  )
}
