/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function SEO(props) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      title={props.title}
      meta={[
        {
          name: `description`,
          content: props.description
        },
        {
          property: `og:title`,
          content: props.title
        },
        {
          property: `og:description`,
          content: props.description
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `og:image`,
          content: `${props.image}`
        },
        {
          name: `og:url`,
          content: props.url
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:url`,
          content: props.url
        },
        {
          name: `twitter:site`,
          content: props.author
        },
        {
          name: `twitter:creator`,
          content: props.author
        },
        {
          name: `twitter:title`,
          content: props.title
        },
        {
          name: `twitter:image`,
          content: `${props.image}`
        },
        {
          name: `twitter:description`,
          content: props.description
        }
      ]
        .concat(
          props.keywords.length > 0
            ? {
                name: `keywords`,
                content: props.keywords.join(`, `)
              }
            : []
        )
        .concat(props.meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
